import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-modal-delete',
  templateUrl: './modal-delete.component.html',
  styleUrls: ['./modal-delete.component.scss'],
})
export class ModalDeleteComponent implements OnInit {
  @Input()
  title: string;

  @Input()
  icon: string;

  @Input()
  message: string;

  @Input()
  btnConfirm: string;

  @Input()
  btnCancel: string;

  @Input()
  customClassCancel;

  @Input()
  customClassConfirm;

  public onClose: Subject<boolean>;

  constructor(private _bsModalRef: BsModalRef) {}

  public ngOnInit(): void {
    this.onClose = new Subject();
  }

  public onConfirm(): void {
    this.onClose.next(true);
    this._bsModalRef.content = true;
    this._bsModalRef.hide();
  }

  public onCancel(): void {
    this.onClose.next(false);
    this._bsModalRef.content = false;
    this._bsModalRef.hide();
  }
}
