import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {
  CurrencyMaskConfig,
  CurrencyMaskModule,
  CURRENCY_MASK_CONFIG,
} from 'ng2-currency-mask';
import { PdfViewerComponent, PdfViewerModule } from 'ng2-pdf-viewer';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { ModalModule } from 'ngx-bootstrap/modal';
import {
  ProgressbarComponent,
  ProgressbarModule,
} from 'ngx-bootstrap/progressbar';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import {
  PerfectScrollbarConfigInterface,
  PerfectScrollbarModule,
  PERFECT_SCROLLBAR_CONFIG,
} from 'ngx-perfect-scrollbar';
import { CardOrderComponent } from './components/card-order/card-order.component';
import { CheckboxDaysOfWeekComponent } from './components/checkbox-day-of-week/checkbox-days-of-week.component';
import { ErrorInputComponent } from './components/error-input/error-input.component';
import { MobilePopupInfoComponent } from './components/mobile-popup-info/mobile-popup-info.component';
import { ModalDeleteComponent } from './components/modal-delete/modal-delete.component';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { PageTitleComponent } from './components/page-title/page-title.component';
import { PasswordValidatorComponent } from './components/password-validator/password-validator.component';
import { PictureUploadComponent } from './components/picture-upload/picture-upload.component';
import { SelectComponent } from './components/select/select.component';
import { TooltipComponent } from './components/tooltip/tooltip.component';
import { UploadFileComponent } from './components/upload-file/upload-file.component';
import { OrderDatePipe } from './pipe/order-date.pipe';
import { OrderStatusPipe } from './pipe/order-status.pipe';
import { SafePipePipe } from './pipe/safe-pipe.pipe';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

export const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
  align: 'right',
  allowNegative: true,
  decimal: ',',
  precision: 2,
  prefix: '',
  suffix: '',
  thousands: '.',
};

@NgModule({
  declarations: [
    CheckboxDaysOfWeekComponent,
    ErrorInputComponent,
    ModalDeleteComponent,
    PageHeaderComponent,
    PageTitleComponent,
    UploadFileComponent,
    PageTitleComponent,
    PictureUploadComponent,
    MobilePopupInfoComponent,
    SelectComponent,
    CardOrderComponent,
    TooltipComponent,
    SafePipePipe,
    OrderDatePipe,
    OrderStatusPipe,
    PasswordValidatorComponent,
  ],
  imports: [
    CommonModule,
    CurrencyMaskModule,
    ModalModule.forRoot(),
    PdfViewerModule,
    ProgressbarModule.forRoot(),
    ReactiveFormsModule,
    TooltipModule.forRoot(),
    TabsModule.forRoot(),
    AccordionModule.forRoot(),
    PerfectScrollbarModule,
    CollapseModule.forRoot(),
    RouterModule,
  ],
  exports: [
    CurrencyMaskModule,
    CheckboxDaysOfWeekComponent,
    ErrorInputComponent,
    ModalDeleteComponent,
    PageHeaderComponent,
    PageTitleComponent,
    PdfViewerComponent,
    ProgressbarComponent,
    UploadFileComponent,
    PictureUploadComponent,
    PasswordValidatorComponent,
    TabsModule,
    PerfectScrollbarModule,
    CollapseModule,
    SelectComponent,
    CardOrderComponent,
    TooltipComponent,
    SafePipePipe,
    OrderDatePipe,
    OrderStatusPipe,
  ],
  providers: [
    { provide: CURRENCY_MASK_CONFIG, useValue: CustomCurrencyMaskConfig },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
  ],
})
export class SharedModule {}
