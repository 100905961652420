import * as moment from 'moment';

import { OrderResponseApiModel } from '../models/order-response-api.model';

import { temrangoLogo } from '../constants/logo';

import { convertToBrl } from './utils/convert-brl';
import { formatPhoneNumber } from './utils/format-phone-number';
import { ProductAndQuantity } from '../models/new-order.model';

/**
 * @description Montar template de 72mm para a cozinha
 *
 * @author Darllinson Azevedo
 *
 * @param order Pedido para ser gerado o template
 */
export function mount72mmKitchenTemplate(order: OrderResponseApiModel) {
  const document: any = {
    pageSize: {
      width: 183,
      height: 'auto',
    },
    pageMargins: [2, 2, 2, 8],
    content: [
      {
        image: temrangoLogo,
        width: 62,
        alignment: 'center',
        margin: [0, 0, 0, 8],
      },
      { text: `${order.metadata.placeName}`, style: 'header' },
      { text: '- - - - - - - - - - - - - - - - - - - - - - - -' },
      {
        text: `${new Date(order.date).toLocaleDateString()}  ${moment(
          order.date
        ).format('LT')}`,
        style: 'textCenter',
      },
      { text: '- - - - - - - - - - - - - - - - - - - - - - - -' },
      { text: `Pedido: ${order.orderNumber}`, style: 'textBoldCenter' },
      { text: '- - - - - - - - - - - - - - - - - - - - - - - -' },
      {
        text: `Contato do cliente: ${formatPhoneNumber(
          order.metadata.user.phoneNumber
        )}`,
        style: 'text',
      },
      { text: '- - - - - - - - - - - - - - - - - - - - - - - -' },
      {
        layout: 'noBorders',
        table: {
          headerRows: 1,
          widths: [25, 95, 60],

          body: [
            [
              { text: 'QTD.', bold: true, fontSize: 7 },
              { text: 'ITENS', bold: true, fontSize: 7 },
              { text: 'R$', bold: true, fontSize: 7 },
            ],
          ],
        },
      },
      { text: '- - - - - - - - - - - - - - - - - - - - - - - -' },
      {
        text: `Compras: ${convertToBrl(order.metadata.total)}`,
        style: 'textMedium',
      },
      {
        text: `Frete/entrega: ${
          order.metadata.shippingPrice
            ? convertToBrl(order.metadata.shippingPrice)
            : 'Grátis'
        }`,
        style: 'textMedium',
      },
      { text: `Cupons/descontos:  - - - - -`, style: 'textMedium' },
      { text: '- - - - - - - - - - - - - - - - - - - - - - - -' },
      {
        text: `${statusPayment(
          order
        )} - ${order.metadata.paymentMethod.name.toUpperCase()}`,
        style: 'textBold12',
      },
      {
        text: `${
          order.metadata.paymentMethod.type.toLowerCase() === 'money' ||
          order.metadata.paymentMethod.type.toLowerCase() === 'pix'
            ? ''
            : 'Com maquininha'
        }`,
        style: 'text12',
      },
      { text: '- - - - - - - - - - - - - - - - - - - - - - - -' },
      { text: 'Conheça o temrango', style: 'footer' },
      { text: 'temrango.com.br', style: 'textBoldCenter' },
      { text: 'lojista.temrango.com.br', style: 'textBoldCenter' },
    ],
    styles: {
      header: {
        fontSize: 14,
        bold: true,
        alignment: 'center',
        lineHeight: 1.2,
      },
      footer: {
        fontSize: 14,
        bold: true,
        alignment: 'center',
        lineHeight: 1.2,
      },

      text: {
        fontSize: 9,
        alignment: 'left',
      },
      textBold: {
        fontSize: 9,
        alignment: 'left',
        bold: true,
      },
      text12: {
        fontSize: 11,
        alignment: 'left',
      },
      textBold12: {
        fontSize: 11,
        alignment: 'left',
        bold: true,
      },
      textMedium: {
        fontSize: 10,
        alignment: 'justify',
        lineHeight: 1.3,
      },

      textCenter: {
        fontSize: 10,
        alignment: 'center',
      },
      textBoldCenter: {
        fontSize: 10,
        alignment: 'center',
        bold: true,
      },
      textBoldLeft: {
        fontSize: 12,
        bold: true,
        alignment: 'left',
      },
    },
  };

  order.metadata.products.map((product) => {
    if (product.product.productComplements.length) {
      document.content[9].table.body.push([
        { text: `${product.quantity}x`, style: 'textBold' },
        {
          text: `${product.product.name} [COD: ${
            product.product.sku || 'N/A'
          }]\nComplementos:\n${createOptionText(product)}`,
          style: 'text',
        },
        {
          text: `${convertToBrl(calcProductPriceWithComplements(product))}`,
          style: 'text',
        },
      ]);
    } else {
      document.content[9].table.body.push([
        { text: `${product.quantity}x`, style: 'textBold' },
        {
          text: `${product.product.name} ${
            product.product.variation
              ? '(' + product.product.variation.name + ')'
              : ''
          } [COD: ${product.product.sku || 'N/A'}]`,
          style: 'text',
        },
        {
          text: `${convertToBrl(
            product.product.variation
              ? product.product.variation.price
              : product.product.price
          )}`,
          style: 'text',
        },
      ]);
    }
  });

  return document;
}

/**
 * @description Montar template de 72mm para o entregador
 *
 * @author Darllinson Azevedo
 *
 * @param order Pedido para ser gerado o template
 */
export function mount72mmMotoboyTemplate(order: OrderResponseApiModel) {
  const document: any = {
    pageSize: {
      width: 183,
      height: 'auto',
    },
    pageMargins: [2, 2, 2, 8],
    content: [
      {
        image: temrangoLogo,
        width: 62,
        alignment: 'center',
        margin: [0, 0, 0, 8],
      },
      { text: `${order.metadata.placeName}`, style: 'header' },
      { text: '- - - - - - - - - - - - - - - - - - - - - - - -' },
      {
        text: `${new Date(order.date).toLocaleDateString()}  ${moment(
          order.date
        ).format('LT')}`,
        style: 'textCenter',
      },
      { text: '- - - - - - - - - - - - - - - - - - - - - - - -' },
      { text: `Pedido: ${order.orderNumber}`, style: 'textBoldCenter' },
      { text: '- - - - - - - - - - - - - - - - - - - - - - - -' },
      {
        layout: 'noBorders',
        table: {
          headerRows: 1,
          widths: [25, 95, 60],

          body: [
            [
              { text: 'QTD.', bold: true, fontSize: 7 },
              { text: 'ITENS', bold: true, fontSize: 7 },
              { text: 'R$', bold: true, fontSize: 7 },
            ],
          ],
        },
      },
      { text: '- - - - - - - - - - - - - - - - - - - - - - - -' },
      {
        text: `TOTAL: ${convertToBrl(order.metadata.total)}`,
        style: 'textMedium',
      },
      { text: '- - - - - - - - - - - - - - - - - - - - - - - -' },
      {
        text: `${statusPayment(
          order
        )} - ${order.metadata.paymentMethod.name.toUpperCase()}`,
        style: 'textBold12',
      },
      {
        text: `${
          order.metadata.paymentMethod.type.toLowerCase() === 'money'
            ? `${
                order.metadata.hasChange
                  ? '\nLevar troco para' +
                    convertToBrl(order.metadata.changeAmount)
                  : '\nSem troco'
              }`
            : 'Com maquininha'
        }`,
        style: 'text12',
      },
      { text: '- - - - - - - - - - - - - - - - - - - - - - - -' },
      {
        text: 'LOCAL DE ENTREGA',
        style: 'textBoldCenter',
      },
      {
        text: `\n${order.metadata.user.address.street.trim()}, ${
          order.metadata.user.address.number
        } ${
          order.metadata.user.address.complement
            ? ', ' + order.metadata.user.address.complement
            : ''
        }`,
        style: 'text',
      },
      {
        text: `Bairro ${order.metadata.user.address.neighborhood}`,
        style: 'text',
      },
      {
        text: `\nReferência: ${order.metadata.user.address.reference}`,
        style: 'text',
      },
      { text: '- - - - - - - - - - - - - - - - - - - - - - - -' },
      {
        text: 'DADOS DO CLIENTE',
        style: 'textBoldCenter',
      },
      {
        text: `\n${order.metadata.user.customerName} ${order.metadata.user.customerLastName}`,
        style: 'text',
      },
      {
        text: `${formatPhoneNumber(order.metadata.user.phoneNumber)}`,
        style: 'text',
      },
      {
        text: `${order.metadata.user.emailAddress}`,
        style: 'text',
      },
    ],
    styles: {
      header: {
        fontSize: 14,
        bold: true,
        alignment: 'center',
        lineHeight: 1.2,
      },
      footer: {
        fontSize: 14,
        bold: true,
        alignment: 'center',
        lineHeight: 1.2,
      },

      text: {
        fontSize: 9,
        alignment: 'left',
      },
      textBold: {
        fontSize: 9,
        alignment: 'left',
        bold: true,
      },
      text12: {
        fontSize: 11,
        alignment: 'left',
      },
      textBold12: {
        fontSize: 11,
        alignment: 'left',
        bold: true,
      },
      textMedium: {
        fontSize: 10,
        alignment: 'justify',
        lineHeight: 1.3,
      },

      textCenter: {
        fontSize: 10,
        alignment: 'center',
      },
      textBoldCenter: {
        fontSize: 10,
        alignment: 'center',
        bold: true,
      },
      textBoldLeft: {
        fontSize: 12,
        bold: true,
        alignment: 'left',
      },
    },
  };

  order.metadata.products.map((product) => {
    if (product.product.productComplements.length) {
      document.content[9].table.body.push([
        { text: `${product.quantity}x`, style: 'textBold' },
        {
          text: `${product.product.name} [COD: ${
            product.product.sku || 'N/A'
          }]\nComplementos:\n${createOptionText(product)}`,
          style: 'text',
        },
        {
          text: `${convertToBrl(calcProductPriceWithComplements(product))}`,
          style: 'text',
        },
      ]);
    } else {
      document.content[9].table.body.push([
        { text: `${product.quantity}x`, style: 'textBold' },
        {
          text: `${product.product.name} ${
            product.product.variation
              ? '(' + product.product.variation.name + ')'
              : ''
          } [COD: ${product.product.sku || 'N/A'}]`,
          style: 'text',
        },
        {
          text: `${convertToBrl(
            product.product.variation
              ? product.product.variation.price
              : product.product.price
          )}`,
          style: 'text',
        },
      ]);
    }
  });

  return document;
}

function statusPayment(order: OrderResponseApiModel): string {
  if (
    order.metadata.isDelivery &&
    order.metadata.paymentMethod.type.toLowerCase() !== 'pix'
  ) {
    return 'PAGAR NA ENTREGA';
  } else if (
    !order.metadata.isDelivery &&
    order.metadata.paymentMethod.type.toLowerCase() !== 'pix'
  ) {
    return 'PAGAR NA RETIRADA';
  } else {
    return 'PAGAMENTO EFETUADO';
  }
}

function calcProductPriceWithComplements(product: ProductAndQuantity): number {
  const complements = product.product.productComplements;
  let totalComplements = 0;

  complements.forEach((complement) => {
    complement.options.forEach((option) => {
      const optionPrice = option.option.price || 0;
      totalComplements += optionPrice * option.quantity;
    });
  });

  return Number((product.product.price + totalComplements).toFixed(2));
}

function createOptionText(product: ProductAndQuantity): string {
  let text = '';

  product.product.productComplements.forEach((c) => {
    c.options.forEach((o) => {
      if (o.quantity) {
        text += `${o.quantity}x ${o.option.name}\n`;
      }
    });
  });

  return text;
}
